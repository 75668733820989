import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest } from "utils/axios-client";

function* fetchTemplates() {
  try {
    const response = yield callApi(() => getRequest("workflow/template"));
    yield put({
      type: actions.FETCH_TEMPLATES_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TEMPLATES_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TEMPLATES, fetchTemplates)]);
}
