export const actions = {
  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT: "LOGOUT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",

  SIGNUP: "SIGNUP",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "SIGNUP_FAILURE",

  LINKEDIN_GET_EMAIL: "LINKEDIN_GET_EMAIL",
  LINKEDIN_GET_EMAIL_SUCCESS: "LINKEDIN_GET_EMAIL_SUCCESS",
  LINKEDIN_GET_EMAIL_FAILURE: "LINKEDIN_GET_EMAIL_FAILURE",

};

export default actions;
