import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequestNoToken, postRequestNoToken } from "utils/axios-client";

function* login(action) {
  try {
    const response = yield callApi(() =>
      postRequestNoToken("auth/signin", action.payload)
    );
    localStorage.setItem("user", "user");
    yield put({
      type: actions.LOGIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    console.log("error message", error);
    let payload = null;
    if (action.payload.socialLogin === 1 && error.response.status === 302) {
      payload = true;
    }
    yield put({ type: actions.LOGIN_FAILURE, payload: payload });
  }
}

function* logout() {
  // mockAdapter.onDelete("/logout").reply(200);

  localStorage.removeItem("user");

  try {
    // yield axiosClient.post("/logout");
    yield put({ type: actions.LOGOUT_SUCCESS });
  } catch (error) {
    yield put({ type: actions.LOGOUT_FAILURE });
  }
}

function* signup(action) {
  try {
    const response = yield callApi(() =>
      postRequestNoToken("auth/signup", action.payload)
    );
    console.log("receive response", response);
    yield put({
      type: actions.SIGNUP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    console.log("errors in signup", error);
    yield put({ type: actions.SIGNUP_FAILURE });
  }
}

function* getEmailFromLinkedIn(action) {
  try {
    const response = yield callApi(() =>
      getRequestNoToken(`auth/linkedin?auth_code=${action.payload}`)
    );
    yield put({
      type: actions.LINKEDIN_GET_EMAIL_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.LINKEDIN_GET_EMAIL_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOGIN, login)]);
  yield all([takeLatest(actions.LOGOUT, logout)]);
  yield all([takeLatest(actions.SIGNUP, signup)]);
  yield all([takeLatest(actions.LINKEDIN_GET_EMAIL, getEmailFromLinkedIn)]);
}
