import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import store from "states/store";

import "assets/css/app.scss";

import App from "App";
import { GoogleOAuthProvider } from "@react-oauth/google";

if (document.getElementById("root")) {
  ReactDOM.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <GoogleOAuthProvider clientId="1084618473456-h1f7cjtofv1jb7niv4a5e3bqnhpm1uog.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Provider>
  );
}
