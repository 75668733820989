import actions from "./actions";

const initialState = {
  columns: [],
  isFetchingColumns: false,
  isCreatingColumns: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_COLUMNS:
      return {
        ...state,
        isSuccessDeleteColumns: null,
        isFetchingColumns: true,
        isCreatingColumns: null,
      };
    case actions.FETCH_COLUMNS_SUCCESS:
      return {
        ...state,
        isFetchingColumns: false,
        columns: action.payload,
      };
    case actions.FETCH_COLUMNS_FAILURE:
      return { ...state, isFetchingColumns: false };

    case actions.CREATE_COLUMNS:
      return {
        ...state,
        isCreatingColumns: null,
      };
    case actions.CREATE_COLUMNS_SUCCESS:
      return {
        ...state,
        isCreatingColumns: true,
      };

    case actions.RESET_COLUMNS:
      return {
        columns: [],
        isFetchingColumns: false,
        isCreatingColumns: null,
      };
    default:
      return state;
  }
}

export default Reducer;
