export const actions = {
  FETCH_PROJECTS: "FETCH_PROJECTS",
  FETCH_PROJECTS_SUCCESS: "FETCH_PROJECTS_SUCCESS",
  FETCH_PROJECTS_FAILURE: "FETCH_PROJECTS_FAILURE",

  CREATE_PROJECTS: "CREATE_PROJECTS",
  CREATE_PROJECTS_SUCCESS: "CREATE_PROJECTS_SUCCESS",
  CREATE_PROJECTS_FAILURE: "CREATE_PROJECTS_FAILURE",

  UPDATE_PROJECTS: "UPDATE_PROJECTS",
  UPDATE_PROJECTS_SUCCESS: "UPDATE_PROJECTS_SUCCESS",
  UPDATE_PROJECTS_FAILURE: "UPDATE_PROJECTS_FAILURE",

  DELETE_PROJECTS: "DELETE_PROJECTS",
  DELETE_PROJECTS_SUCCESS: "DELETE_PROJECTS_SUCCESS",
  DELETE_PROJECTS_FAILURE: "DELETE_PROJECTS_FAILURE",

  RESET_RESPONSE: "RESET_RESPONSE"

};

export default actions;
