export const AntConfigProps = {
  theme: {
    token: {
      colorPrimary: "#ff8d00",
      colorLink: "#ff8d00",
      borderRadius: 0,
      borderRadiusLG: 0,
      colorBgLayout: "#ffffff",
      colorBgContainer: "#fff",
      fontSize: 14,
      fontFamily: "Petlink",
    },
  },
};

export const AntConfigMobileProps = {
  theme: {
    token: {
      colorPrimary: "#ff8d00",
      colorLink: "#ff8d00",
      borderRadius: 10,
      borderRadiusLG: 0,
      colorBgLayout: "#ffffff",
      colorBgContainer: "#fff",
      fontSize: 12,
      fontFamily: "Petlink",
    },
  },
};
