import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { postRequestNoToken } from "utils/axios-client";

function* resetPassword(action) {
  try {
    const response = yield callApi(() => postRequestNoToken('auth/resetPassword', action.payload))
    yield put({
      type: actions.RESET_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    yield put({ type: actions.RESET_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.RESET, resetPassword)]);
}
