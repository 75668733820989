import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest } from "utils/axios-client";

function* fetchTasks(action) {
  try {
    const response = yield callApi(() => getRequest(`workflow/task?projectId=${action.projectId}`));
    yield put({
      type: actions.FETCH_TASKS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_TASKS_FAILURE });
  }
}
function* createTasks(action) {
  try {
    const response = yield callApi(() => postRequest(`workflow/task?projectId=${action.projectId}`, action.payload));
    yield put({
      type: actions.CREATE_TASKS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_TASKS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_TASKS, fetchTasks)]);
  yield all([takeLatest(actions.CREATE_TASKS, createTasks)]);
}
