import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "utils/axios-client";

function* fetchProjects() {
  try {
    const response = yield callApi(() => getRequest("workflow/project"));
    yield put({
      type: actions.FETCH_PROJECTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_PROJECTS_FAILURE });
  }
}

function* createProject(action) {
  try {
    const response = yield callApi(() =>
      postRequest("workflow/project", action.payload)
    );
    yield put({
      type: actions.CREATE_PROJECTS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_PROJECTS_FAILURE });
  }
}

function* updateProject(action) {
  try {
    yield callApi(() =>
      putRequest(`workflow/project?id=${action.id}`, action.payload)
    );
    yield put({
      type: actions.UPDATE_PROJECTS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_PROJECTS_FAILURE });
  }
}

function* deleteProject(action) {
  try {
    yield callApi(() => deleteRequest(`workflow/project?id=${action.payload}`));
    yield put({
      type: actions.DELETE_PROJECTS_SUCCESS,
    });
  } catch (error) {
    yield put({ type: actions.DELETE_PROJECTS_FAILURE });
  }
}
export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_PROJECTS, fetchProjects)]);
  yield all([takeLatest(actions.CREATE_PROJECTS, createProject)]);
  yield all([takeLatest(actions.UPDATE_PROJECTS, updateProject)]);
  yield all([takeLatest(actions.DELETE_PROJECTS, deleteProject)]);
}
