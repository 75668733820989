import React, { Suspense, createContext, lazy, useMemo, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "components/ui/loader/Loader";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { useMobileViewport } from "utils/responsive";
import { ConfigProvider } from "antd";
import { AntConfigMobileProps, AntConfigProps } from "config/AntConfig";
const CustomerRoutes = lazy(() => import("routes/Customer"));
const AuthRoutes = lazy(() => import("routes/Auth"));

export const AppContextData = createContext(null);

function App() {
  const isMobileView = useMobileViewport();
  const ConfigProps = useMemo(() => {
    if (isMobileView) return AntConfigMobileProps;
    else return AntConfigProps;
  }, [isMobileView]);
  const [choosedProject, setChoosedProject] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [showComponent, setShowComponent] = useState("dashboard");
  return (
    <ConfigProvider {...ConfigProps}>
      <Suspense fallback={<Loader />}>
        <AppContextData.Provider
          value={{
            choosedProject,
            setChoosedProject,
            selectedProject,
            setSelectedProject,
            showComponent,
            setShowComponent,
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/linkedin" Component={LinkedInCallback} />
              <Route path="/*" element={<CustomerRoutes />} />
              <Route path="/auth/*" element={<AuthRoutes />} />
            </Routes>
          </BrowserRouter>
        </AppContextData.Provider>
      </Suspense>
    </ConfigProvider>
  );
}

export default App;
