export const actions = {
  FETCH_COLUMNS: "FETCH_COLUMNS",
  FETCH_COLUMNS_SUCCESS: "FETCH_COLUMNS_SUCCESS",
  FETCH_COLUMNS_FAILURE: "FETCH_COLUMNS_FAILURE",

  CREATE_COLUMNS: "CREATE_COLUMNS",
  CREATE_COLUMNS_SUCCESS: "CREATE_COLUMNS_SUCCESS",
  CREATE_COLUMNS_FAILURE: "CREATE_COLUMNS_FAILURE",

  RESET_COLUMNS: "RESET_COLUMNS",
};

export default actions;
