import { all, put, takeLatest } from "redux-saga/effects";
import actions from "./actions";
import { callApi } from "states/saga";
import { getRequest, postRequest } from "utils/axios-client";

function* fetchColumns(action) {
  try {
    const response = yield callApi(() =>
      getRequest(`workflow/custom_columns?projectId=${action.projectId}`)
    );
    yield put({
      type: actions.FETCH_COLUMNS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.FETCH_COLUMNS_FAILURE });
  }
}
function* createColumns(action) {
  try {
    const response = yield callApi(() =>
      postRequest(
        `workflow/custom_columns?projectId=${action.projectId}`,
        action.payload
      )
    );
    yield put({
      type: actions.CREATE_COLUMNS_SUCCESS,
      payload: response.data.data,
    });
  } catch (error) {
    yield put({ type: actions.CREATE_COLUMNS_FAILURE });
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.FETCH_COLUMNS, fetchColumns)]);
  yield all([takeLatest(actions.CREATE_COLUMNS, createColumns)]);
}
