export const actions = {
  FETCH_TASKS: "FETCH_TASKS",
  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  FETCH_TASKS_FAILURE: "FETCH_TASKS_FAILURE",

  CREATE_TASKS: "CREATE_TASKS",
  CREATE_TASKS_SUCCESS: "CREATE_TASKS_SUCCESS",
  CREATE_TASKS_FAILURE: "CREATE_TASKS_FAILURE",

  RESET_TASKS: "RESET_TASKS",
};

export default actions;
