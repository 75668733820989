import actions from "./actions";

const initialState = {
  isReseted: null,
  user: null,
  isReseting: false,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.RESET:
      return {
        ...state,
        isReseting: true,
        isReseted: null,
      };
    case actions.RESET_SUCCESS:
      return {
        ...state,
        isReseted: true,
        isReseting: false,
        user: action.payload,
      };
    case actions.RESET_FAILURE:
      return { ...state, isReseted: false, isReseting: false };

    default:
      return state;
  }
}

export default Reducer;
