import { combineReducers } from "redux";
import loginReducer from "states/auth/sign/reducer";
import requestPassReducer from "states/auth/reqresetpass/reducer";
import resetPassReducer from "states/auth/reset/reducer";
// import signupReducer from "states/auth/signup/reducer";

// workflow reducers
import workflowProjectReducer from "states/workflow/project/reducer";
import workflowTemplateReducer from "states/workflow/templates/reducer";
import workflowTaskReducer from "states/workflow/tasks/reducer";
import workflowColumnReducer from "states/workflow/custom_columns/reducer";

//other reducers
import fileManagerReducer from "states/others/common_file_manager/reducer";
const rootReducer = combineReducers({
  login: loginReducer,
  requestPass: requestPassReducer,
  resetPass: resetPassReducer,
  workflowProject: workflowProjectReducer, //workflow reducer
  workflowTemplate: workflowTemplateReducer,
  workflowTask: workflowTaskReducer,
  workflowColumn: workflowColumnReducer,
  fileManager: fileManagerReducer,
});

export default rootReducer;
