import actions from "./actions";

const initialState = {
  projects: null,
  isFetchingProjects: false,

  createProject: null,

  isSuccessCreateProjects: null,
  isSuccessUpdateProjects: null,
  isSuccessDeleteProjects: null,
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_PROJECTS:
      return {
        ...state,
        createProject: null,
        isSuccessDeleteProjects: null,
        isSuccessUpdateProjects: null,
        isFetchingProjects: true,
      };
    case actions.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isFetchingProjects: false,
        projects: action.payload,
      };
    case actions.FETCH_PROJECTS_FAILURE:
      return { ...state, isFetchingProjects: false };

    case actions.CREATE_PROJECTS:
      return {
        ...state,
        isSuccessCreateProjects: null,
      };
    case actions.CREATE_PROJECTS_SUCCESS:
      return {
        ...state,
        isSuccessCreateProjects: true,
        createProject: action.payload,
      };
    case actions.CREATE_PROJECTS_FAILURE:
      return { ...state, isSuccessCreateProjects: false };

    case actions.UPDATE_PROJECTS:
      return {
        ...state,
        isSuccessUpdateProjects: null,
      };
    case actions.UPDATE_PROJECTS_SUCCESS:
      return {
        ...state,
        isSuccessUpdateProjects: true,
      };
    case actions.UPDATE_PROJECTS_FAILURE:
      return { ...state, isSuccessUpdateProjects: false };

    case actions.DELETE_PROJECTS:
      return {
        ...state,
        isSuccessDeleteProjects: null,
      };
    case actions.DELETE_PROJECTS_SUCCESS:
      return {
        ...state,
        isSuccessDeleteProjects: true,
      };
    case actions.DELETE_PROJECTS_FAILURE:
      return { ...state, isSuccessDeleteProjects: false };

    case actions.RESET_RESPONSE:
      return {
        projects: null,
        isFetchingProjects: false,

        isSuccessCreateProjects: null,
        isSuccessUpdateProjects: null,
        isSuccessDeleteProjects: null,
      };

    default:
      return state;
  }
}

export default Reducer;
