import actions from "./actions";

const currentUser = localStorage.getItem("user")
const initialState = {
  isAuthenticated: currentUser ? true : false,
  user: currentUser ? currentUser : null,
  isAuthenticating: false,

  emailInfoFromLinkedin: null,
  shouldRegist: null,

  isSigned: null,
  isSigning: false,
  test: null
};


function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOGIN:
      return {
        ...state,
        shouldRegist: null,
        isSigned: null,
        isAuthenticating: true,
        emailInfoFromLinkedin: null
      };
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        isSigned: null,
        user: action.payload,
      };
    case actions.LOGIN_FAILURE:
      return { ...state, isAuthenticated: false, isSigned: null, isAuthenticating: false, shouldRegist: action.payload };

    case actions.LOGOUT:
      return {
        ...state,
        emailInfoFromLinkedin: null,
        user: null,
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case actions.LOGOUT_FAILURE:
      return { ...state, isAuthenticated: false };

    case actions.SIGNUP:
      return {
        ...state,
        shouldRegist: null,
        isAuthenticating: true,
        isSigned: null,
        emailInfoFromLinkedin: null,
      };
    case actions.SIGNUP_SUCCESS:
      return {
        ...state,
        test: action.payload,
        isAuthenticating: false,
        isSigned: true,
        isSigning: false,
      };
    case actions.SIGNUP_FAILURE:
      return { ...state, isSigned: false, isSigning: false, isAuthenticating: false, };

    case actions.LINKEDIN_GET_EMAIL:
      return { ...state, emailInfoFromLinkedin: null };

    case actions.LINKEDIN_GET_EMAIL_SUCCESS:
      return { ...state, emailInfoFromLinkedin: action.payload };

    case actions.LINKEDIN_GET_EMAIL_FAILURE:
      return { ...state, emailInfoFromLinkedin: null };

    default:
      return state;
  }
}

export default Reducer;
