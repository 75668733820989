import actions from "./actions";

const initialState = {
  templates: [],
  isFetchingTemplates: false,
};


function Reducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_TEMPLATES:
      return {
        ...state,
        isSuccessDeleteTemplates: null,
        isFetchingTemplates: true,
      };
    case actions.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        isFetchingTemplates: false,
        templates: action.payload,
      };
    case actions.FETCH_TEMPLATES_FAILURE:
      return { ...state, isFetchingTemplates: false };

    default:
      return state;
  }
}

export default Reducer;
