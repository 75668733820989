import { all, call, put } from "redux-saga/effects";
import { notification } from "antd";
import loginSaga from "states/auth/sign/saga";
import requestPassSaga from "states/auth/reqresetpass/saga";
import resetPassSaga from "states/auth/reset/saga";
// import signupSaga from "states/auth/signup/saga";

//workflow sagas
import workflowProjectSaga from "states/workflow/project/saga";
import workflowTemplateSaga from "states/workflow/templates/saga";
import workflowTaskSaga from "states/workflow/tasks/saga";
import workflowColumnSaga from "states/workflow/custom_columns/saga";

//others sagas
import fileManagerSaga from "states/others/common_file_manager/saga";
import actions from "./auth/sign/actions";
import Cookies from "js-cookie";

// Here you can include all the saga which you write for components
export default function* rootSaga() {
  yield all([
    loginSaga(),
    requestPassSaga(),
    resetPassSaga(),
    workflowProjectSaga(), //workflow sagas
    workflowTemplateSaga(),
    workflowTaskSaga(),
    workflowColumnSaga(),
    // signupSaga(),
    fileManagerSaga(),
  ]);
}

export function* callApi(apiFunction, ...args) {
  try {
    // yield call(() => getCustomRequest('sanctum/csrf-cookie'));
    const response = yield call(apiFunction, ...args);
    handleSuccessfulResponse(response);
    return response;
  } catch (error) {
    console.log("when responese status is 401", error.response.data);
    if (error.response.status === 401) yield put({ type: actions.LOGOUT });
    handleError(error);
    throw error;
  }
}

function handleSuccessfulResponse(response) {
  if (response.status === 200 && response.data.message) {
    showNotification("success", "Success!", response.data.message);
  }
}

function handleError(error) {
  if (error.response) {
    const errorHandlerMapping = {
      302: () =>
        showNotification("success", "Welcome", "Please complete registration."),
      403: () => {
        Cookies.remove("token");
        Cookies.remove("username");
        localStorage.removeItem("user");
        // setTimeout(function () {
        window.location.reload();
        // }, 100);
      },
      405: () => {
        const validationErrors = error.response.data.message;
        showNotification("error", "Log in failed", `${validationErrors}`);
      },
      500: () =>
        showNotification(
          "error",
          "Temporary server problem",
          "We are currently experiencing some technical issues. Our engineers are working on the issue and we hope to have it resolved soon. Please try again later."
        ),
      422: () => {
        const validationErrors = error.response.data.message;
        const errorMessages = Object.values(validationErrors).flat().join("\n");

        showNotification(
          "error",
          "Validation failed",
          `It appears that there was a problem with the information provided: ${errorMessages} Please check and try again.`
        );
      },
    };
    const errorHandler =
      errorHandlerMapping[error.response.status] ||
      (() =>
        showNotification(
          "error",
          "Unexpected error",
          `Oops, something went wrong: ${error.response.data.message}. Please try again, or contact support if the problem persists.`
        ));
    errorHandler();
  }
}

function showNotification(type, title, message) {
  notification[type]({
    duration: 5,
    message: title,
    description: message,
  });
}
